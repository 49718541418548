@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url("./assets/fonts/Avenir_Medium.ttf")  format("truetype");
}
@font-face {
  font-family: 'AvenirBold';
  src: local('AvenirBold'), url("./assets/fonts/Avenir_Black.ttf")  format("truetype");
}
@font-face {
  font-family: 'av';
  src: local('av'), url("./assets/fonts/Avenir.ttc")  format("truetype");
}

body {
  overflow-x:hidden ;
  background-color: #000;
  margin: 0; 

  font-family: 'Avenir',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/* background-color: #eee; */
/* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


video{
  display:none;
}

#header{
  border: 1px solid darkred;
  position: fixed;
  color:darkred;
  padding:5px;
  
  background-color: #f442;
}

@keyframes rectFade{
  0% {transform: scale(1.0);}
  30% { transform: scale(0.6);}
  /* 40% { transform: scale(0.4);} */
  100% { transform: scale(1.0);} 
}

@keyframes sound{
  0% {opacity:0;}
  100% { opacity: 1;} 
}

@keyframes handmove{
  0% {transform: translateX(-10px)} 
  50% {transform: translateX(10px)} 
  100% {transform: translateX(-10px)} 
}

@keyframes rotateCircle{
  0% {transform: rotateZ(8deg)} 
  50% {transform: rotateZ(-8deg)} 
  100% {transform: rotateZ(8deg)} 
}

@keyframes hemateAnimation{
  0% {transform: translateY(-8px) rotateZ(-5deg)} 
  90% {transform: translateY(0px) rotateZ(-5deg)} 
  100% {transform: rotateZ(0px) rotateZ(0deg)} 
}
@keyframes cursorDrag{
  0% {transform: translateX(-180px)} 
  50% {transform: translateX(180px)} 
  100% {transform: translateX(-180px)} 

}

.cursorDrag{
  transition: 2s all linear;
   /* display: none;  */
   animation: cursorDrag 4s linear infinite;
  transform-origin: center; 

}

.c1{
  animation: rectFade 2s ease infinite;
  animation-delay: 0.1s;
  transform-origin: center; 
} 

.c2{
  animation: rectFade 2s ease infinite;
  animation-delay: 0.3s;
  transform-origin: center; 
} 

.c3{
  animation: rectFade 2s ease infinite;
  margin:50px;
  animation-delay: 0.1s;
  transform-origin: center; 
} 
.c4{
  animation: rectFade 2s ease infinite;
  animation-delay: 0.3s;
  color:"white";
  transform-origin: center; 
  position: fixed;
  top:100px;
  left:100px
} 

.cc1{
  animation: rectFade 1.5s ease infinite;
  animation-delay: 0.15s;
  transform-origin: center; 
}
.cc2{
  animation: rectFade 1.5s ease infinite;
  /* animation-delay: 0.1s; */
  transform-origin: center; 
}

.s2{
  animation: sound 1.4s ease infinite;
  animation-delay: 0s;

  transform-origin: center; 
}
.s3{
  animation: sound 1.4s ease infinite;
  animation-delay: 0.3s;

  transform-origin: center; 
}
.s4{
  animation: sound 1.4s ease infinite;
  animation-delay: 0.5s;
  transform-origin: center; 
}

.rotateCircle{
  /* display: none; */
  transform-origin: center; 
  animation: rotateCircle 1.6s linear infinite;
}

.rotateHand{
  animation: handmove 1.6s linear infinite;
  transform-origin: center; 
}

.hemate{
  animation: hemateAnimation 0.8s linear ;
  transform-origin: top;
}

#workerIconPosFIx{
  margin-top: 100px;
}

#hotspotIcon{
  margin-top: 135px;
}

.workerIconPosFIx{
  transform: translate(-16px, 45px);
}
 

.labelx{
  color:"white";
}

.discovered:hover{
  /* cursor: pointer; */
}
.notdiscovered:hover{
  /* cursor: pointer; */
}
.hotspotContainer{ 
  border-radius: 100%;
   /* border: 2px solid red; */
  /* background:"#fff" !important; */
  padding:42px;
}

.dotpagination{
  /* margin-top: 40px; */
}

.textInput{
  border: none;
  width:86%;
  border-radius:2px;
  
  padding: 20px 22px;
  margin-top: 5px;
}

.textInputlabel{

  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;

}
@media (max-width:800px) {
  .textInput{
    padding: 13px 22px;
    width:100%;
  }
  .textInputlabel{
    font-size: 14px;

  }
}
 

@keyframes hourglass-rotation {
	50%{transform: rotateZ(0);}
	100%{transform: rotateZ(180deg);}
}

@keyframes face {
	0%{opacity: 0;}
	100%{opacity: 1;}
}
@keyframes face2 {
	0%{opacity: 1;}
	100%{opacity: 0;}
}


#top-sand #top-clip-path rect,
#bottom-sand path,
#bottom-sand g,
svg {
	animation-duration: 5s;
	animation-delay: 1s; 
	animation-iteration-count: infinite;
}

#top-sand #top-clip-path rect {
	animation-name: top-clip;
}

@keyframes hg {
	0%{transform: translateY(0px);}
	100%{transform: translateY(60px);}
}
#hg{
  /* display: none; */
	animation-name: hg;
  /* height:277px; */
	animation-duration: 5s;
	animation-delay: 1s; 
	animation-iteration-count: infinite;
}
#bottom-sand path {
   
	/* transform: translateY(-60px); */
	animation-name: bottom-sand-path;
}

#bottom-sand g {
	animation-name: bottom-sand-g;
}

.playScreen  #Time2SVG { 
    margin-top: 110px; 
}

#Time2SVG{
	animation-name: hourglass-rotation;

}
 

.time ,.Tempo {

  margin-bottom: -50px;
    margin-left: -43px;
    /* margin-top: 110px; */
}

 .A_rightEye2 , .A_mouth2{
  animation: face 1.4s linear ;
}

.A_rightEye1, .A_mouth1 {
  /* display: none; */
  animation: face2 1s linear forwards;
}

@keyframes ficha1 {
	0%{ transform: translate(0px ,0px);}
	100%{ transform: translate(-10px ,10px);}
}

@keyframes ficha2 {
	0%{ transform: translate(0px ,0px);}
	100%{ transform: translate(10px ,-10px);}
}
.ficha1{
  /* display: none; */
  /* animation: ficha1 1s ease-out forwards; */
 
  transform-origin: center;
}
.ficha2{
  /* display: none; */
  /* animation: ficha2 1s ease-out forwards; */
 
  transform-origin: center;
}

@keyframes cpu1 {
	100%{transform: translate(0 ,9px);}
}
@keyframes cpu2 {
	100%{transform: translate(0 ,-9px);}
}
@keyframes cpu3 {
	100%{transform: translate(9px ,0);}
}
@keyframes cpu4 {
	100%{transform: translate(-9px ,0);}
}
 
@keyframes box {
	0%{
    opacity:1;
  }
  80%{
    opacity:0;  
  }
  100%{
    transform: translate(400px ,0);
    opacity:0;  
  }
}

@keyframes box2 {
	0%{
    opacity:0;
  }
  80%{
    opacity:1;  
  }
  100%{
    transform: translate(100px ,0);
    opacity:1;  
  }
}
.a_box{
  transform:translateX(100px);
  /* transform: translate(-170px ,0); */ 
  /* animation-iteration-count: 10; */
  /* transform: translate(0px ,0); */
}
 
 

.boxLeft{
  /* display: none; */
  transform:translateX(-200px);
 
  /* animation-iteration-count: 10; */
}

/* .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 10s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
} */

@keyframes pcFade {
	0%{
    opacity:0;
  }
  100%{
    opacity:1;  
  }
}



.pc1, .pc2, .pc3, .pc4, .pc5, .pc6, .pc7{
  opacity:0;
  /* animation: pcFade  0.4s linear forwards; */

  
}
.pc1{
  animation-delay: 0.5s;
}

.pc2{
  animation-delay: 1s;
  
}

.pc3{
  animation-delay: 1.5s;
  
}

.pc4{
  animation-delay: 2s;
  
}

.pc5{
  animation-delay: 2.5s;
  
}

.pc6{
  animation-delay: 3s;
  
}

.pc7{
  animation-delay: 3.5s;
}

::-webkit-scrollbar-track {
  background:rgba(233, 131, 0, 0.85);
}
::-webkit-scrollbar-thumb:hover {
  
  background: #f55; 
} 

#canvContainer{
  max-height: 100vh;
  overflow: hidden;
}

/* TODO REMOVE */
  @media (min-width:800px) {
  body{

    overflow: hidden !important;
  }
  }




.MuiPaper-root{
  border-radius: 0 !important;
}
/* .MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm  */